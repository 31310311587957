import Home from "./page/home/home"
import Profile from "./page/profile/profile"
import Login from "./page/auth/login"
import Global from "./global"


let glob = Global.getInstance();
glob.checkAuth(function(){
    var root = document.body;
    m.route(root, "/", {
        "/": Home,
        "/login": Login,
        "/profile": Profile
    });
});