import classNames from "classnames";
import Global from "../../global"
import Validator from "validator"

var Login = {
    oninit: function(){
        this.showerror = false;
        this.texterror = '';
        this.data = {
            email: '',
            password: '',
            remember: ''
        }
        this.error = {
            email: false,
            password: false
        }
        let glob = Global.getInstance();
        if(glob.getUser())
        {
            location.href = "/";
            //location.reload();
        }
    },
    view: function() {
        let self = this;
        return m('div', {class: classNames('page')}, [
            m('div', {class: 'page-single'}, [
                m('div', {class: 'container'}, [
                    m('div', {class: 'row'}, [
                        m('div', {class: 'col col-login mx-auto'}, [
                            m('div', {class: 'text-center mb-6'}, [
                                m('img', {class: 'h-6', src: '/assets/images/kakiatna.png'})
                            ]),
                            m('form', {onsubmit: function(e){
                                e.preventDefault();
                                self.error.email = !Validator.isEmail(self.data.email);
                                self.error.password = !Validator.isLength(self.data.password, {min:5, max: 400});

                                if(!self.error.email &&
                                    !self.error.password)
                                    {
                                        m.request({
                                            method: "POST",
                                            url: "/api/v1/auth/login",
                                            body: {
                                                email: self.data.email,
                                                password: self.data.password,
                                                remember: true
                                            },
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            if(data.result)
                                            {
                                                location.href = '/';
                                                location.reload();
                                                
                                            }
                                            else
                                            {
                                                self.showerror = true;
                                                self.texterror = data.error[0].message;
                                            }
                                        });
                                    }

                            }, class: 'card'}, [
                                m('div', {class: 'card-body p-6'}, [
                                    m('div', {class: 'card-title'}, 'Login to your account'),
                                    m('div', {class: classNames('alert alert-danger', {hidden: !self.showerror})}, self.texterror),
                                    m('div', {class: 'form-group'}, [
                                        m('label', {class: 'form-label'}, 'Email address'),
                                        m('input', {oninput: function(e){
                                            self.data.email = e.target.value;
                                            self.error.email = !Validator.isEmail(self.data.email);
                                        }, value: self.data.email, class: classNames('form-control', {'is-invalid': self.error.email}), type:'email', 'aria-describedby':"emailHelp", placeholder:"Enter email"}),
                                        m('div', {class: classNames({ 'invalid-feedback': self.error.email}, { 'hidden': !self.error.email})}, 'Harap masukan email dengan benar')
                                    ]),
                                    m('div', {class: 'form-group'}, [
                                        m('label', {class: 'form-label'}, 'Password'),
                                        m('input', {oninput: function(e){
                                            self.data.password = e.target.value;
                                            self.error.password = !Validator.isLength(self.data.password, {min:5, max: 400});
                                        }, value: self.data.password, class: classNames('form-control', {'is-invalid': self.error.password}), type:'password', placeholder:"Password"}),
                                        m('div', {class: classNames({ 'invalid-feedback': self.error.password}, { 'hidden': !self.error.password})}, 'minimal password 5 karakter')
                                    ]),
                                    m('div', {class: 'form-group'}, [
                                        m('label', {class: 'custom-control custom-checkbox'}, [
                                            m('input', {onclick: function(e){
                                                self.data.remember = e.target.value;
                                            }, checked: (self.data.remember) ? 'checked' : '', class: 'custom-control-input', type:'checkbox'}),
                                            m('span', {class: 'custom-control-label'}, 'Remember me')
                                        ]),
                                    ]),
                                    m('div', {class: 'form-footer'}, [
                                        m('button', {class: 'btn btn-primary btn-block', type:'submit'}, 'Sign in')
                                    ]),
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    }
}
module.exports = Login;
