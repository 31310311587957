
var Footer = {
    view: function() {
        let self = this;
        return m('div', {class: 'footer'}, [
            m('div', {class: 'container'}, [
                m('div', {class: 'row'}, [
                    m('div', {class: 'col-lg-8'}, [
                        m('div', {class: 'row'}, [
                            m('div', {class: 'col-6 col-md-3'}, [
                                m('ul', {class: 'list-unstyled mb-0'}, [
                                    m('li', [
                                        m('a', 'Link Satu')
                                    ]),
                                    m('li', [
                                        m('a', 'Second link')
                                    ])
                                ])
                            ]),
                            m('div', {class: 'col-6 col-md-3'}, [
                                m('ul', {class: 'list-unstyled mb-0'}, [
                                    m('li', [
                                        m('a', 'First link')
                                    ]),
                                    m('li', [
                                        m('a', 'Second link')
                                    ])
                                ])
                            ]),
                            m('div', {class: 'col-6 col-md-3'}, [
                                m('ul', {class: 'list-unstyled mb-0'}, [
                                    m('li', [
                                        m('a', 'First link')
                                    ]),
                                    m('li', [
                                        m('a', 'Second link')
                                    ])
                                ])
                            ]),
                            m('div', {class: 'col-6 col-md-3'}, [
                                m('ul', {class: 'list-unstyled mb-0'}, [
                                    m('li', [
                                        m('a', 'First link')
                                    ]),
                                    m('li', [
                                        m('a', 'Second link')
                                    ])
                                ])
                            ])
                        ])
                    ]),
                    m('div', {class: 'col-12 col-lg-auto mt-3 mt-lg-0 text-center'}, [
                        'Copyright © 2019',
                        m('a', 'kakiatna'),
                        'All rights reserved.'
                    ])
                ])
            ])
        ])
    }
}
module.exports = Footer;