import classNames from "classnames";
import Global from "../global"

var Header = {
    oninit: function(){
        let glob = Global.getInstance();
        this.user = glob.getUser();
        this.avatar = '/assets/images/2.jpg';
        if(!this.user)
        {
            location.href = "/#!/login";
            //location.reload();
        }
        else
        {
            if(this.user.avatar)
            {
                this.avatar = 'https://img.rakfoto.com/'+this.user.avatar.fid;
            }
        }
    },
    view: function() {
        let self = this;
        return m('div', [
            m('div', {class: classNames('header', 'py-4')}, [
                m('div', {class: classNames('container')}, [
                    m('div', {class: classNames('d-flex')}, [
                        m('a', {class:classNames('header-brand')}, [
                            m('img', {src: '/assets/images/kakiatna.png', class: classNames('header-brand-img'), alt:"tabler logo"})
                        ]),
                        m('div', {class: classNames('d-flex', 'order-lg-2', 'ml-auto')}, [
                            m('div', {class: classNames('dropdown', 'd-none', 'd-md-flex')}, [
                                m('a', {class: classNames('nav-link', 'icon'), 'data-toggle':"dropdown"}, [
                                    m('i', {class: classNames('fe', 'fe-bell')}),
                                    m('span', {class: 'nav-unread'})
                                ]),
                                m('div', {class: classNames('dropdown-menu', 'dropdown-menu-right', 'dropdown-menu-arrow')}, [
                                    m('a', {class: classNames('dropdown-item', 'd-flex')},[
                                        m('span', {class: classNames('avatar', 'mr-3', 'align-self-center'), style:"background-image: url(/assets/images/1.jpg)"}),
                                        m('div', [
                                            m('strong', 'Nathan'),
                                            'pushed new commit: Fix page load performance issue.',
                                            m('div', {class: classNames('small', 'text-muted')}, '10 minutes ago')
                                        ])
                                    ]),
                                    m('a', {class: classNames('dropdown-item', 'd-flex')},[
                                        m('span', {class: classNames('avatar', 'mr-3', 'align-self-center'), style:"background-image: url(/assets/images/2.jpg)"}),
                                        m('div', [
                                            m('strong', 'Nathan'),
                                            'pushed new commit: Fix page load performance issue.',
                                            m('div', {class: classNames('small', 'text-muted')}, '10 minutes ago')
                                        ])
                                    ]),
                                    m('div', {class:'dropdown-divider'}),
                                    m('a', {class: classNames('dropdown-item', 'd-flex')},[
                                        m('span', {class: classNames('avatar', 'mr-3', 'align-self-center'), style:"background-image: url(/assets/images/2.jpg)"}),
                                        m('div', [
                                            m('strong', 'Nathan'),
                                            'pushed new commit: Fix page load performance issue.',
                                            m('div', {class: classNames('small', 'text-muted')}, '10 minutes ago')
                                        ])
                                    ])
                                ])
                            ]),
                            m('div', {class: classNames('dropdown')},[
                                m('a', {class: classNames('nav-link', 'pr-0', 'leading-none'), 'data-toggle':"dropdown"}, [
                                    m('span', {class: classNames('avatar'), style:"background-image: url("+self.avatar+")"}),
                                    m('span', {class: classNames('ml-2', 'd-none', 'd-lg-block')},[
                                        m('span', {class: classNames('text-default')}, self.user.first_name+' '+self.user.last_name),
                                        m('span', {class: classNames('text-muted', 'd-block', 'mt-1')}, 'Administrator')
                                    ])
                                ]),
                                m('div', {class: classNames('dropdown-menu', 'dropdown-menu-right', 'dropdown-menu-arrow')}, [
                                    m('a', {onclick: function(e){
                                        m.route.set('/profile');
                                    },class: classNames('dropdown-item')},[
                                        m('i', {class: classNames('dropdown-icon', 'fe', 'fe-user')}),
                                        'Profile'
                                    ]),
                                    m('a', {class: classNames('dropdown-item')},[
                                        m('i', {class: classNames('dropdown-icon', 'fe', 'fe-settings')}),
                                        'Settings'
                                    ]),
                                    m('a', {class: classNames('dropdown-item')},[
                                        m('i', {class: classNames('dropdown-icon', 'fe', 'fe-log-out')}),
                                        'Sign out'
                                    ])
                                ])
                            ])
                        ]),
                        m('a', {class: 'header-toggler d-lg-none ml-3 ml-lg-0', 'data-toggle':"collapse", 'data-target':"#headerMenuCollapse"}, [
                            m('span', {class: 'header-toggler-icon'})
                        ])
                    ])
                ])
            ]),
            //
            m('div', {class: classNames('header', 'collapse', 'd-lg-flex', 'p-0'), id:"headerMenuCollapse"}, [
                m('div', {class: 'container'},[
                    m('div', {class: classNames('row', 'align-items-center')},[
                        m('div', {class: classNames('col-lg-3', 'ml-auto')},[
                            m('form', {class: classNames('input-icon', 'my-3', 'my-lg-0')},[
                                m('input', {type:"search", class: classNames('form-control', 'header-search'), placeholder:"Search...", tabindex:"1"}),
                                m('div', {class: 'input-icon-addon'}, [
                                    m('i', {class: classNames('fe', 'fe-search')})
                                ])
                            ])
                        ]),
                        m('div', {class: 'col-lg order-lg-first'},[
                            m('ul', {class: 'nav nav-tabs border-0 flex-column flex-lg-row'}, [
                                m('li', {class: 'nav-item'}, [
                                    m('a', {class: 'nav-link'},[
                                        m('i', {class: 'fe fe-home'}),
                                        'Home'
                                    ])
                                ]),
                                m('li', {class: 'nav-item', 'data-toggle':"dropdown"}, [
                                    m('a', {class: 'nav-link'},[
                                        m('i', {class: 'fe fe-box'}),
                                        'Interface'
                                    ]),
                                    m('div', {class: 'dropdown-menu dropdown-menu-arrow'}, [
                                        m('a', {class:"dropdown-item "}, 'Cards design'),
                                        m('a', {class:"dropdown-item "}, 'Cards design')
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
            //
        //    m('div', {class: 'my-3 my-md-5'},'ok')
        ])
    }
}
module.exports = Header;