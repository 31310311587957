import classNames from "classnames";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Global from "../../global"

var progress = 0;

var Profile = {
    oncreate: function(){
        //
    },
    oninit: function(){
        let self = this;
        this.onupload = false;
        this.frmdata = undefined;
        this.data = {
            first_name: '',
            last_name: '',
            company: '',
            email: '',
            password: '',
            departement: '',
            jabatan: '',
            address: '',
            city: '',
            postal: '',
            country: 'indonesia',
            about: '',
            bio: '',
            avatar: '/assets/images/avatar.jpeg'
        }
        let glob = Global.getInstance();
        if(!glob.getUser())
        {
            location.href = "/#!/login";
            //location.reload();
        }
        else
        {
            m.request({
                method: "POST",
                url: "/api/v1/auth/get",
                body: {
                    
                },
                withCredentials: true,
            })
            .then(function(data) {
                if(data.result)
                {
                    if(data.data.first_name)
                        self.data.first_name = data.data.first_name;
                    if(data.data.last_name)
                        self.data.last_name = data.data.last_name;
                    if(data.data.company)
                        self.data.company = data.data.company;
                    self.data.email = data.data.email;

                    if(data.data.department)
                        self.data.department = data.data.department;
                    if(data.data.jabatan)
                        self.data.jabatan = data.data.jabatan;
                    if(data.data.address)
                        self.data.address = data.data.address;
                    if(data.data.city)
                        self.data.city = data.data.city;
                    if(data.data.postal)
                        self.data.postal = data.data.postal;
                    if(data.data.country)
                        self.data.country = data.data.country;
                    if(data.data.about)
                        self.data.about = data.data.about;

                    if(data.data.avatar)
                    {
                        self.data.avatar = 'https://img.rakfoto.com/'+data.data.avatar.fid;
                    }
                    if(data.data.bio)
                    {
                        self.data.bio = data.data.bio;
                    }
                }
            });
        }
    },
    view: function() {
        let self = this;
        return m('div', {class: classNames('page')}, [
            m('div', {class: classNames('flex-fill')}, [
                m(Header),
                m('div', {class: 'my-3 my-md-5'}, [
                    m('div', {class: 'container'}, [
                        m('div', {class: 'row'}, [
                            m('div', {class: 'col-lg-4'}, [
                                m('div', {class: 'card'}, [
                                    m('div', {class: 'card-header'}, [
                                        m('h3', {class: 'card-title'}, 'My Profile')
                                    ]),
                                    m('div', {class: 'card-body'}, [
                                        m('form', {onsubmit: function(e){
                                            //
                                            e.preventDefault();
                                            if(self.frmdata)
                                            {
                                                self.onupload = !self.onupload;

                                                m.request({
                                                    method: "POST",
                                                    url: "/api/v1/auth/avatar/upload",
                                                    body: self.frmdata,
                                                    config: function(xhr) {
                                                        xhr.upload.addEventListener("progress", function(e) {
                                                            progress = e.loaded / e.total;
                                                            if(progress >= 1)
                                                            {
                                                                progress -= 0.05;
                                                            }
                                                            var elem = document.getElementById("myBar");
                                                            elem.style.width = progress + "%";
                                                            //m.redraw() // tell Mithril that data changed and a re-render is needed
                                                        })
                                                    }
                                                })
                                                .then(function(data) {
                                                    if(!data.result)
                                                    {
                                                        alert('upload image gagal');
                                                    }
                                                    else
                                                    {
                                                        //self.filename = file.name;
                                                        //self.data.image = data.data.image;
                                                        //alert('insert customer sukses ', data.data.image);
                                                        //m.route.set('/myphoto');
                                                        //location.reload();
                                                        //self.SetProgress(1);
                                                    }
                                                    //self.loader = false;
                                                    //callback();
                                                    self.onupload = !self.onupload;
                                                });

                                                
                                            }

                                            m.request({
                                                method: "POST",
                                                url: "/api/v1/auth/data/update1",
                                                body: {
                                                    password: self.data.password,
                                                    bio: self.data.bio
                                                },
                                                withCredentials: true,
                                            })
                                            .then(function(data) {
                                                if(data.result)
                                                {
                                                    alert('SUCCESS UPDATE');
                                                }
                                                else{
                                                    alert('FAILED UPDATE');
                                                }
                                            });


                                            
                                        }}, [
                                            m('div', {class: 'row'}, [
                                                m('div', {class: 'col-auto'}, [
                                                    m('label', {class: 'avatar avatar-xl', style:"background-image: url("+self.data.avatar+")"}, [
                                                        m('input', {onchange: function(e){
                                                            //self.loader = true;
                                                            //var file = this.files[0];
                                                            //let glob = Global.getInstance();
                                                            //alert('ok');
                                                            self.frmdata = new FormData();
                                                            var file = this.files[0];
                                                            self.frmdata.append('photos[]', file, file.name);
                                                            let url = URL.createObjectURL(file);
                                                            self.data.avatar = url;
                                                            console.log('file url : ', url);

                                                        }, class: 'hidden', type: 'file', accept: 'image/*'}),
                                                    ]),
                                                    m('div', {id:'myProgress', class: classNames({'hidden': !self.onupload})}, [
                                                        m('div', {id:'myBar'})
                                                    ])
                                                ]),
                                                m('div', {class: 'col'}, [
                                                    m('div', {class: 'form-group'}, [
                                                        m('label', {class: 'form-label'}, 'Email-Address'),
                                                        m('input', {class: 'form-control', disabled: 'disabled', type:"email", placeholder:"your-email@domain.com", value: self.data.email})
                                                    ])
                                                ])
                                            ]),
                                            m('div', {class: 'form-group'}, [
                                                m('label', {class: 'form-label'}, 'Bio'),
                                                m('textarea', {oninput: function(e){
                                                    self.data.bio = e.target.value;
                                                },class: 'form-control', rows:"5"}, self.data.bio)
                                            ]),
                                            m('div', {class: 'form-group'}, [
                                                m('label', {class: 'form-label'}, 'Password'),
                                                m('input', {oninput: function(e){
                                                    self.data.password = e.target.value;
                                                }, class: 'form-control', type:"password", value: self.data.password})
                                            ]),
                                            m('div', {class: 'form-footer'}, [
                                                m('button', {type: 'submit', class: 'btn btn-primary btn-block'}, 'Save')
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            m('div', {class: 'col-lg-8'}, [
                                m('form', {class: 'card'}, [
                                    m('form', {onsubmit: function(e){

                                        e.preventDefault();
                                        m.request({
                                            method: "POST",
                                            url: "/api/v1/auth/data/update2",
                                            body: {
                                                department: self.data.department,
                                                jabatan: self.data.jabatan,
                                                first_name: self.data.first_name,
                                                last_name: self.data.last_name,
                                                address: self.data.address,
                                                city: self.data.city,
                                                postal: self.data.postal,
                                                country: self.data.country,
                                                about: self.data.about
                                            },
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            if(data.result)
                                            {
                                                alert('SUCCESS UPDATE');
                                            }
                                            else{
                                                alert('FAILED UPDATE');
                                            }
                                        });
                                    }, class: 'card-body'}, [
                                        m('h3', {class: 'card-title'}, 'Edit Profile'),
                                        m('div', {class: 'row'}, [
                                            m('div', {class: 'col-md-5'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Company'),
                                                    m('input', {type:"text", class: 'form-control', disabled: 'disabled', placeholder:"Company", value: self.data.company})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-3'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Departemen'),
                                                    m('input', {oninput: function(e){
                                                        self.data.departement = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"Departemen", value: self.data.departement})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-4'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Jabatan'),
                                                    m('input', {oninput: function(e){
                                                        self.data.jabatan = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"Jabatan", value: self.data.jabatan})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-6'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'First Name'),
                                                    m('input', {oninput: function(e){
                                                        self.data.first_name = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"First Name", value: self.data.first_name})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-6'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Last Name'),
                                                    m('input', {oninput: function(e){
                                                        self.data.last_name = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"Last Name", value: self.data.last_name})
                                                ])
                                            ]),
                                            m('div', {class: 'col-md-12'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Address'),
                                                    m('input', {oninput: function(e){
                                                        self.data.address = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"Address", value: self.data.address})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-4'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'City'),
                                                    m('input', {oninput: function(e){
                                                        self.data.city = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"City", value: self.data.city})
                                                ])
                                            ]),
                                            m('div', {class: 'col-sm-6 col-md-3'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Postal Code'),
                                                    m('input', {oninput: function(e){
                                                        self.data.postal = e.target.value;
                                                    },type:"text", class: 'form-control', placeholder:"Postal Code", value: self.data.postal})
                                                ])
                                            ]),
                                            m('div', {class: 'col-md-5'}, [
                                                m('div', {class: 'form-group'}, [
                                                    m('label', {class: 'form-label'}, 'Country'),
                                                    m('select', {onchange: function(e){
                                                        self.data.country = e.target.value;
                                                    },class: 'form-control custom-select'}, [
                                                        m('option', {value: 'indonesia', selected: (self.data.country == 'indonesia') ? 'true' : ''}, 'Indonesia'),
                                                    ])
                                                ])
                                            ]),
                                            m('div', {class: 'col-md-12'}, [
                                                m('div', {class: 'form-group mb-0'}, [
                                                    m('label', {class: 'form-label'}, 'About Me'),
                                                    m('textarea', {oninput: function(e){
                                                        self.data.about = e.target.value;
                                                    },rows:"5", class: 'form-control', placeholder:"About Me"}, self.data.about)
                                                ])
                                            ]),
                                        ]),
                                        //
                                        m('div', {class: 'card-footer text-right'}, [
                                            m('button', {type:'submit', class:"btn btn-primary"}, 'Update Profile')
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ]),
            m(Footer)
        ])
    }
}
module.exports = Profile;

