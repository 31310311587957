import classNames from "classnames";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Global from "../../global"

var Home = {
    oninit: function(){
        let glob = Global.getInstance();
        if(!glob.getUser())
        {
            location.href = "/#!/login";
            //location.reload();
        }
    },
    view: function() {
        let self = this;
        return m('div', {class: classNames('page')}, [
            m('div', {class: classNames('flex-fill')}, [
                m(Header),
                m('div', {class: 'my-3 my-md-5'},'ok')
            ]),
            m(Footer)
        ])
    }
}
module.exports = Home;

