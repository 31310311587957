
var Global = (function(){
    var self = {};
    var instance;
    var user;

    self.checkAuth = function(callback){
        m.request({
            method: "POST",
            url: "/api/v1/auth/get",
            body: {},
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                user = data.data;
                
            }
            if(callback)
            {
                callback();
            }
        });
    }

    self.getUser = function(){
        return user;
    }

    function createInstance() {
        
        return self;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

export default Global;